import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function loadList(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/?${filter || ''}`,
        method: "get"
    });
}

export function loadServers(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/servers/?${filter || ''}`,
        method: "get"
    });
}
export function loadRegistrators(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/domain-registrators/?${filter || ''}`,
        method: "get"
    });
}

export function createDomain(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/create/`,
        method: "post",
        data
    });
}

export function edit(data: any, id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/${id}/update/`,
        method: "patch",
        data
    });
}

export function domainsReplace(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/domains/replace/`,
        method: "post",
        data
    });
}
export function domainsMigrate(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/domain-migrate/`,
        method: "post",
        data
    });
}
