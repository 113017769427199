import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function loadList(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/?${filter || ''}`,
        method: "get"
    });
}

export function loadDeatils(id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/${id}/`,
        method: "get"
    });
}

export function create(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/create/`,
        method: "post",
        data
    });
}

export function edit(data: any, id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/${id}/update/`,
        method: "patch",
        data
    });
}

export function editDraft(data: any, id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/${id}/draft/`,
        method: "patch",
        data
    });
}

export function upload(data: any, id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/${id}/upload/`,
        method: "post",
        headers: {
            "Content-Disposition": `attachment; filename=${typeof data === "string" ? data : data.name
                }`
        },
        data
    });
}

export function loadCountries(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/countries/?${filter || ''}`,
        method: "get"
    });
}
export function createCountries(data?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/countries/`,
        method: "post",
        data
    });
}

export function loadNetworks(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/networks/?${filter || ''}`,
        method: "get"
    });
}

export function loadFiles(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/files/?${filter || ''}`,
        method: "get"
    });
}

export function deleteFile(id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/files/${id}/delete/`,
        method: "delete"
    });
}

export function loadInterests(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/interests/?${filter || ''}`,
        method: "get"
    });
}
// export function getOfferArchive(id: any) {
//     return Http._axios({
//         url: `${service_url}/api/v1/offers/${id}/files/`,
//         method: "get"
//     });
// }

export function loadTargetOfffers(filter?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/target_offers/?${filter || ''}`,
        method: "get"
    });
}
export function createTargetOfffers(data?: string) {
    return Http._axios({
        url: `${service_url}/api/v1/target_offers/`,
        method: "post",
        data
    });
}

export function createOfferCopy(id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/${id}/clone/`,
        method: "patch"
    });
}

export function downloadOffers(id: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/${id}/files/`,
        method: "get",
        responseType: "blob",
        headers: {
            "Content-Type": "application/octet-stream"
        },
    });
}



export function endUrlsReplace(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/offers/domain-replace/`,
        method: "post",
        data
    });
}