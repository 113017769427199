<template>
  <v-app>
    <router-view />
    <tooltip-component />
  </v-app>
</template>

<script>
import Vue from "vue";
import TooltipComponent from "./components/TooltipComponent";

export default Vue.extend({
  name: "App",
  components: { TooltipComponent },
  data: () => ({}),
  created() {
    window.getApp = this;
  },
});
</script>

<style lang="scss">
@import "./assets/styles";
@import "./assets/codeEditor";
</style>
