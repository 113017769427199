import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    list: [],
    count: 0,
    status: { change_field: null },
    details: {},
    files_status: {},
    networks: [],
    countries: [],
    created_offer: {},
    uploadedFiles: [],
    files: [],
    target_offers: []
};

export default {
    state,
    actions,
    mutations,
    getters
};
