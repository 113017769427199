import * as TYPES from "./types";

const default_state = {
    count: 0,
    list: [],
    status: {change_field: null},
    details: {},
    countries: [],
    networks: [],
    interests: [],
    uploadedFiles: [],
    created_offer: {},
    files_status: {},
    files: [],
    target_offers: []
};

export default {
    [TYPES.SET_ERROR](state: any, error: any) {
        state.errors = error;
    },
    [TYPES.RECEIVE_LIST](state: any, data: any) {
        state.list = data.results;
        state.count = data.count;
    },
    [TYPES.RECEIVE_DETAILS](state: any, payload: any) {
        state.details = payload;
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
            file_name: payload.file_name,
            editing_offer: payload.editing_offer,
        };
    },
    [TYPES.SET_FILE_STATUS](state: any, payload: any) {
        state.files_status = {
            ...state.files_status,
            [payload.file_name]: payload.status
        };
    },
    [TYPES.RECEIVE_COUNTRIES](state: any, data: any) {
        state.countries = data.results;
    },
    [TYPES.RECEIVE_FILE](state: any, data: any) {
        state.uploadedFiles = [...state.uploadedFiles, data];
    },
    [TYPES.RECEIVE_FILES](state: any, data: any) {
        state.files = data.results;
    },
    [TYPES.RECEIVE_NETWORKS](state: any, data: any) {
        state.networks = data.results;
    },
    [TYPES.RECEIVE_TARGET_OFFERS](state: any, data: any) {
        state.target_offers = data;
    },
    [TYPES.RECEIVE_INTERESTS](state: any, data: any) {
        state.interests = data.results;
    },
    [TYPES.RECEIVE_CREATED_OFFER](state: any, payload: any) {
        state.created_offer = payload;
    },
    [TYPES.SET_DEFAULT_STATE](state: any) {
        state.list = default_state.list;
        state.count = default_state.count;
        state.status = default_state.status;
        state.details = default_state.details;
        state.files_status = default_state.files_status;
        state.countries = default_state.countries;
        state.networks = default_state.networks;
        state.created_offer = default_state.created_offer;
        state.uploadedFiles = default_state.uploadedFiles;
        state.files = default_state.files;
    }
};
