// action types
export const LOAD_LIST = "LOAD_LIST";
export const CREATE_DOMAINS = "CREATE_DOMAINS";
export const EDIT_DOMAINS = "EDIT_DOMAINS";
export const CLEAR_LIST = "CLEAR_LIST";

// mutation types
export const RECEIVE_LIST = "RECEIVE_LIST";
export const SET_ERROR = "SET_ERROR";
export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE";
export const SET_STATUS = "SET_STATUS";