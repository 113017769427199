import * as TYPES from "./types";
import * as API from "./api";
import { requestStatus } from "@/services/services";
import { editDraft } from "./api";

export default {
    [TYPES.LOAD_LIST]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadList(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_LIST, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load offers error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.LOAD_DETAILS]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_details";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadDeatils(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_DETAILS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load offer detail error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.CREATE]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "create";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.create(data)
            .then(({ status: statusField, messages: messagesField, response }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_CREATED_OFFER, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Create offers error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    async [TYPES.EDIT]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "edit";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field, editing_offer: data.id });
    
        try {
            const response = await API.edit(data.data, data.id);
            status = response.status;
    
            if (status === requestStatus.error) {
                messages = response.messages || 'Edit offers error';
            }
    
            return status;  // Додано return для повернення значення
        } finally {
            commit(TYPES.SET_STATUS, {
                status: status === requestStatus.loading ? requestStatus.error : status,
                field,
                messages
            });
        }
    },
    [TYPES.EDIT_DRAFT]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "edit_draft";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field, editing_offer: data.id });

        API.editDraft(data.data, data.id)
            .then(({ status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Edit offers error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },
    [TYPES.UPLOAD]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "upload";
        const file_name = data.file_name;
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });
        commit(TYPES.SET_FILE_STATUS, { status, file_name });

        API.upload(data.data, data.id)
            .then(({ status: statusField, messages: messagesField, response }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_FILE, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Edit offers error'
                }
            })
            .finally(() => {
                const result_status = status === requestStatus.loading ? requestStatus.error : status;
                commit(TYPES.SET_FILE_STATUS, { status: result_status, file_name });
                commit(TYPES.SET_STATUS, {
                    status: result_status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_COUNTRIES]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_countries";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadCountries(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_COUNTRIES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load countries error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_NETWORKS]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_networks";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadNetworks(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_NETWORKS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load networks error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_TARGET_OFFERS]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_target_offers";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadTargetOfffers(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_TARGET_OFFERS, response.data.results);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load Target offers error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_INTERESTS]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_networks";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadInterests(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_INTERESTS, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load interests error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.LOAD_FILES]({ commit }: any, data: string) {
        let status = requestStatus.loading;
        const field = "load_files";
        let messages = '';
        commit(TYPES.SET_STATUS, { status, field });

        API.loadFiles(data)
            .then(({ response, status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.success) {
                    commit(TYPES.RECEIVE_FILES, response.data);
                }
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Load files error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            })
    },

    [TYPES.DELETE_FILE]({ commit }: any, data: any) {
        let status = requestStatus.loading;
        const field = "delete_file";
        let messages = '';
        const file_name = data.file_name;
        commit(TYPES.SET_STATUS, { status, field, file_name });

        API.deleteFile(data.id)
            .then(({ status: statusField, messages: messagesField }: any) => {
                status = statusField;
                if (statusField === requestStatus.error) {
                    messages = messagesField || 'Delete file error'
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    file_name,
                    messages
                });
            })
    },
}