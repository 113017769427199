// action types
export const LOAD_LIST = "LOAD_LIST";
export const LOAD_DETAILS = "LOAD_DETAILS";
export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const EDIT_DRAFT = "EDIT_DRAFT";
export const UPLOAD = "UPLOAD";
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_NETWORKS = "LOAD_NETWORKS";
export const LOAD_TARGET_OFFERS = "LOAD_TARGET_OFFERS";
export const DELETE_FILE = "DELETE_FILE";
export const LOAD_FILES = "LOAD_FILES";
export const LOAD_INTERESTS = "LOAD_INTERESTS";

// mutation types
export const RECEIVE_LIST = "RECEIVE_LIST";
export const RECEIVE_DETAILS = "RECEIVE_DETAILS";
export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";
export const RECEIVE_NETWORKS = "RECEIVE_NETWORKS";
export const RECEIVE_TARGET_OFFERS = "RECEIVE_TARGET_OFFERS";
export const SET_ERROR = "SET_ERROR";
export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE";
export const SET_STATUS = "SET_STATUS";
export const SET_FILE_STATUS = "SET_FILE_STATUS";
export const RECEIVE_CREATED_OFFER = "RECEIVE_CREATED_OFFER";
export const RECEIVE_FILE = "RECEIVE_FILE";
export const RECEIVE_FILES = "RECEIVE_FILES";
export const RECEIVE_INTERESTS = "RECEIVE_INTERESTS";
