import moment from "moment";

export const requestStatus = {
    loading: "loading",
    success: "success",
    error: "error",
    error_connection: "error_connection",
    logout: "logout"
};

export const BROADCASTS_STATUS = {
    WAITING: 0,
    PROCESSING: 10,
    ERROR: 20,
    SUCCESS: 50
}

export const DATE_FORMAT = "DD.MM.YYYY";
export const renderDate = (date: Date) => {
    return moment(date).format(DATE_FORMAT);
};

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const renderDateTime = (date: Date) => {
    return moment.parseZone(date).format(DATE_TIME_FORMAT);
};

export function buildFilterString(query: { [key: string]: string | number | boolean }) {
    let result = "";
    for (const key in query) {
        result +=
            query[key] !== undefined
                ? `&${key}=${query[key]}`
                : "";
    }
    return result.substring(1, result.length);
}

export const isEmptyObject = (obj: { [key: string]: string | number | boolean }) => {
    return Object.keys(obj).length === 0;
}

export const toBooleanValue = (value: string | boolean ) => {
    return (value + '' === 'true' || value + '' === 'false') ? Boolean(value) : ''
};


export function onCopyToClipboard(value: string) {
    const body = window.parent.document.querySelector("body");
    const input = window.parent.document.createElement("input");
    body && body.appendChild(input);
    input.value = value;
    input.select();
    document.execCommand("copy");
    body && body.removeChild(input);
    return true;
}

// eslint-disable-next-line