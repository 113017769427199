import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "/",
        redirect: "/offers",
        component: () => import('@/scenes/AuthPage.vue'),
        children: [
            {
                path: '/domains',
                name: 'domains',
                component: () => import('@/containers/Domains/Domains.vue')
            },
            {
                path: '/offers',
                name: 'offers',
                component: () => import('@/containers/Offers/Offers.vue')
            },
            {
                path: '/offers/create',
                name: 'offer-create',
                component: () => import('@/containers/Offers/OfferCreate.vue')
            },
            {
                path: '/offers/:id',
                name: 'offer-details',
                component: () => import('@/containers/Offers/Offer.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/scenes/Login.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
