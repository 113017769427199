<template>
  <div />
</template>

<script>
import { mapState } from "vuex";
import "v-toaster/dist/v-toaster.css";
import { requestStatus } from "@/services/services";

export default {
  name: "TooltipComponent",
  computed: {
    ...mapState({
      status_domains: state => state.domains.status,
      status_offers: state => state.offers.status,
    })
  },
  methods: {
    checkStatus: function(status) {
      if (status[status.change_field] === requestStatus.error) {
        this.$toaster.error(status.messages || "Bad request");
      }
    }
  },
  watch: {
    status_domains(newValue) {
      this.checkStatus(newValue);
    },
    status_offers(newValue) {
      this.checkStatus(newValue);
    }
  }
};
</script>

<style scoped></style>