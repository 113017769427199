import axios from "axios";
import { requestStatus } from "./services";

interface IResponseError {
    status: string;
    code?: number;
    messages?: string;
}

class Http {
    static _axios(config: any) {
        if (!config || !config.url) {
            throw Object.assign({
                message: "Http request error: config required"
            });
        }

        config.method = config.method || "get";

        if (
            config.url &&
            config.url.indexOf("http") === -1 &&
            config.url.indexOf("https") === -1 &&
            config.url[0] !== "/"
        )
            config.url = "/" + config.url;

        const user = JSON.parse(localStorage.getItem("user") || 'null');
        if (user && user.token) {
            axios.defaults.headers.common["Authorization"] = `Token ${user.token}`;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        // axios.defaults.headers.common["Ngrok-Skip-Browser-Warning"] = "true";


        return axios(config)
            .then((response: any) => {
                return { response: response, status: requestStatus.success };
            })
            .catch((error: any) => {
                if (
                    error.response &&
                    error.response.status &&
                    (+error.response.status === 401 || +error.response.status === 403)
                ) {
                    //unauthorization
                    localStorage.removeItem("user");

                    window.location.href = '/login'
                }
                let response_error: IResponseError = {
                    status: requestStatus.error,
                    code: +error.response.status
                };
                if (error.response && error.response.data && error.response.status) {
                    switch (+error.response.status) {
                        case 405:
                        case 400:
                            response_error = {
                                status: requestStatus.error,
                                messages: buildErrorMessages(error.response.data)
                            };
                            break;
                        case 500:
                            response_error = {
                                status: requestStatus.error,
                                messages: "Server Error. Try again later."
                            };
                            break;
                        default:
                            response_error = { status: requestStatus.error };
                    }
                }
                return response_error;
            });
    }
}

const buildThreeErrorMessages: (param: any) => string = data => {
    let error_msg = "";
    for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
            const key_print = `${key}` === "0" ? "" : `${key.replace(/_/g, " ")}: `;
            if (typeof data[key] === "object") {
                error_msg += `${key_print}${buildThreeErrorMessages(data[key])}\n`;
            } else {
                error_msg += `${key_print}${data[key]}\n`;
            }
        }
    }
    return error_msg;
};

const buildErrorMessages: (param: any) => string = data => {
    if (data.detail) {
        return data.detail;
    } else {
        if (data.non_field_errors) {
            return data.non_field_errors[0];
        }
        if (data.error) {
            return data.error;
        } else {
            // return JSON.stringify(data);

            // for (let key in data) {
            //   if (data.hasOwnProperty(key)) {
            //     error_msg += `${key.replace(/_/g, " ")}: ${data[key]}\n`
            //   }
            // }
            return buildThreeErrorMessages(data);
        }
    }
};

export default Http;
